import { notification, Skeleton, Tooltip } from 'antd';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { RightSideDrawerForCustomizeStatusUpdate } from 'components/sharedComponents';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import { useIsInViewport } from 'components/sharedComponents/hooks/useIsInViewport';
import cryptoRandomString from 'crypto-random-string';
import { SectionTypeForViewPort } from 'models/enums/notification';
import { TaskListView } from 'models/enums/tasklistView';
import {
  ICustomFieldState,
  IProjectData,
  IProjectSections,
  IProjectState,
  ITaskList,
} from 'models/interface';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { loggerService, projectService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import {
  addRemoveOverLay,
  colorChanger,
  findCollapseStateFromStorage,
  getCurrentOrganization,
  storeSectionListStatusInLocalStorage,
} from 'utils/commonFunctions';
import CreateCustomField from '../CreateCustomField';
import MeatBallMenu from '../MeatBallMenu';
import SectionFilters from '../SectionFilters';
import TaskListDetails2 from '../TaskListV2/TaskListDetails';
import { mappingDetails } from '../TaskListV2/services/table.service';
import TaskListCards from './CardView/TaskListCardView';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';

interface ITaskListComponent {
  sectionDetails: IProjectSections;
  // socket: any;
  projectColor?: string;
  refDiv: React.RefObject<HTMLDivElement>;
  setCurrentId?: (val: string) => void;
  currentId?: string;
  forPnc?: boolean;
}

const TaskList: React.FC<ITaskListComponent> = ({
  sectionDetails,
  // socket,
  projectColor = '#dbe8ff',
  refDiv,
  forPnc,
}) => {
  const {
    projectDetails,
    taskListCmsData,
    customFields,
    setProjectDetails,
    setCustomFields,
    customfieldCmsData,
    setProjectActiveTab,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  const [isEditingSectionName, setIsEditingSectionName] = useState(false);
  const [sectionName, setSectionName] = useState<string>('');
  const [sectionToggleState, setSectionToggleState] = useState<
    'expanded' | 'collapsed'
  >('expanded');
  const [taskListDetails, setTaskListDetails] =
    useState<ITaskList>(sectionDetails);
  const [isLoadingTaskListDetails, setIsLoadingTaskListDetails] =
    useState(true);
  const [currentTaskListView, setCurrentTaskListView] = useState('card');
  const [currentSection, setCurrentSection] = useState<{
    sectionId: number;
    popup: 'assignee' | 'dueDate' | 'message';
  }>();
  const [showSidePanelForCustomField, setShowSidePanelForCustomField] =
    useState<ICustomFieldState>({
      show: false,
      fieldMetaData: null,
    });
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [isTaskEmpty, setIsTaskEmpty] = useState(true);
  const urlParams = useParams();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { hasPermissions } = useRbac();
  const [_api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const simpleTaskListSection = useRef(null);
  let isSimpleTaskListSectionInViewport = useIsInViewport(
    simpleTaskListSection,
  ); /* make the default value as true for the PRODUCTION release on 21st Feb 2024 */

  const { fetchNewPermission } = useFetchNewPermission();
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();

  const getSectionDetails = async () => {
    setIsLoadingTaskListDetails(true);
    const payload = {
      project_id: urlParams.projectId!,
      organization_id: getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!,
      section_id: sectionDetails.section_id?.toString(),
    };
    try {
      const response = await projectService.getTasklistDetailsV2(payload);
      if (!response.data.task_list_details) {
        setTaskListDetails(
          mappingDetails({
            ...response.data,
            task_list_details: [
              {
                task_id: cryptoRandomString({ length: 5 }),
                created_by: userDetails?.user_id,
                task_status_id: 1,
                task_priority_id: 2,
                show: true,
                due_date: null,
                task_name: '',
                task_rank: 1,
                assignee_dp: undefined,
                assignee_id: null,
                assigner_id: null,
                assignee_name: undefined,
                assignee_email: undefined,
                task_status_name: 'To Do',
                task_priority_name: 'Normal',
                custom_data: [],
              },
            ],
          }),
        );
        setIsTaskEmpty(true);
      } else {
        setTaskListDetails(response.data);
        setIsTaskEmpty(false);
      }
      setIsLoadingTaskListDetails(false);
    } catch (error) {
      setIsLoadingTaskListDetails(false);
      await loggerService.log({
        severity: 'High',
        message: 'Failed to fetch tasklist details',
        payload: payload,
      });
    }
  };

  const changeTaskListView = () => {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails?.organization_info)
      ?.organization_id!;

    let taskListState = findCollapseStateFromStorage(
      sectionDetails,
      userDetails.user_id.toString(),
      projectId.toString(),
      orgId.toString(),
    );
    let taskListView = 'card';
    if (taskListState && taskListState.view) {
      if (taskListState.view === TaskListView.CARD) {
        taskListView = TaskListView.LIST;
        storeSectionListStatusInLocalStorage(
          sectionDetails,
          userDetails.user_id.toString(),
          projectId.toString(),
          orgId.toString(),
          undefined,
          TaskListView.LIST,
          undefined,
        );
      }
      if (taskListState.view === TaskListView.LIST) {
        taskListView = TaskListView.CARD;
        storeSectionListStatusInLocalStorage(
          sectionDetails,
          userDetails.user_id.toString(),
          projectId.toString(),
          orgId.toString(),
          undefined,
          TaskListView.CARD,
          undefined,
        );
      }
    } else {
      taskListView = TaskListView.CARD;
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId.toString(),
        orgId.toString(),
        undefined,
        TaskListView.CARD,
        undefined,
      );
    }

    setCurrentTaskListView(taskListView);
  };

  const sectionNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.currentTarget?.value;
    setSectionName(value);
  };

  const sectionNameOnBlurHandler = async (
    _event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    setIsEditingSectionName(false);
    setSectionName((prev) => prev?.trim());

    if (projectDetails?.project_id && projectDetails?.sections) {
      if (
        typeof sectionDetails?.section_id === 'string' &&
        sectionName === ''
      ) {
        setProjectDetails({
          ...projectDetails,
          sections: projectDetails?.sections?.filter(
            (eachSection) =>
              eachSection.section_id !== sectionDetails.section_id,
          ),
        });
        setProjectActiveTab(projectDetails.sections?.[1]?.section_id);
      } else if (
        typeof sectionDetails?.section_id === 'string' &&
        sectionName !== ''
      ) {
        await createSection(projectDetails);
        setProjectDetails({
          ...projectDetails,
          sections: projectDetails?.sections?.filter(
            (eachSection) => typeof eachSection.section_id === 'number',
          ),
        });
      } else if (
        typeof sectionDetails?.section_id === 'number' &&
        sectionName?.trim() !== '' &&
        taskListDetails?.section_name !== sectionName
      ) {
        await updateSection(projectDetails);
      }
    }
  };

  const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sectionNameOnBlurHandler(event);
    }
  };

  const handleSectionToggle = () => {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails?.organization_info)
      ?.organization_id!;

    let collapseState = findCollapseStateFromStorage(
      sectionDetails,
      userDetails.user_id.toString(),
      projectId,
      orgId,
    );
    setSectionToggleState((prev) =>
      collapseState && collapseState.collapsed ? 'expanded' : 'collapsed',
    );
    //console.log("COLLAPSED STATE -> ", collapseState);
    if (collapseState && collapseState.collapsed) {
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId,
        orgId,
        false,
        undefined,
        undefined,
      );
    } else {
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId,
        orgId,
        true,
        undefined,
        undefined,
      );
    }
  };

  const updateSection = async (projectDetails: IProjectData) => {
    try {
      const result = await projectService.updateSection({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        project_id: projectDetails.project_id!,
        sections: [
          {
            section_id: +sectionDetails.section_id,
            section_name: sectionName,
          },
        ],
      });

      if (result.messageId === 1) {
        setProjectDetails({
          ...projectDetails,
          sections: projectDetails.sections?.map((e, i) => {
            if (e.section_id === sectionDetails.section_id) {
              return {
                ...sectionDetails,
                section_name: sectionName,
              };
            }
            return e;
          }),
        });
        setTaskListDetails((prev) => {
          return { ...prev, section_name: sectionName };
        });

        // notificationService.sendSectionUpdateNotification(socket, {
        //   eventType: NotificationEventType.UPDATE_SECTION_NAME,
        //   eventValue: sectionName,
        //   sectionType: SectionType.SIMPLE_TASK_LIST,
        //   currentOrganizationId: getCurrentOrganization(
        //     org_key,
        //     userDetails?.organization_info
        //   )?.organization_id,
        //   currentProjectId: projectDetails.project_id,
        //   currentSectionId: sectionDetails.section_id,
        //   currentUserId: userDetails.user_id,
        //   currentUserName: userDetails.full_name
        //     ? userDetails.full_name
        //     : userDetails.email,
        // });
      }
    } catch (error: any) {
      fetchNewPermission(
        org_key,
        taskListCmsData?.lbl_error_message_permission_denied,
      );
    }
  };

  const createSection = async (projectDetails: IProjectData) => {
    try {
      const response = await projectService.createNewSection({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        project_id: projectDetails.project_id!,
        section_type_id: 1,
        section_name: sectionName,
        section_rank: sectionDetails.section_rank,
      });

      if (response.data.data) {
        setProjectDetails({
          ...projectDetails,
          sections: projectDetails?.sections?.map(
            (eachSection: IProjectSections) => {
              const _section = eachSection;
              if (_section.section_id === sectionDetails.section_id) {
                _section.section_id = response.data.data;
                _section.section_name = sectionName;
                setTaskListDetails((prev: ITaskList | null) => {
                  return {
                    ...prev,
                    ..._section,
                    section_id: response.data.data,
                    section_name: sectionName,
                    pack_id: 2,
                    status_done_id: 1,
                  };
                });
              }
              return _section;
            },
          ),
        });
        setProjectActiveTab(response.data.data);
        setIsLoadingTaskListDetails(false);
        // notificationService.sendSectionUpdateNotification(socket, {
        //   eventType: NotificationEventType.ADD_NEW_SECTION,
        //   eventValue: "",
        //   sectionType: SectionType.SIMPLE_TASK_LIST,
        //   currentOrganizationId: getCurrentOrganization(
        //     org_key,
        //     userDetails?.organization_info
        //   )?.organization_id,
        //   currentProjectId: projectDetails?.project_id,
        //   currentSectionId: "",
        //   currentUserId: userDetails.user_id,
        //   currentUserName: userDetails.full_name
        //     ? userDetails.full_name
        //     : userDetails.email,
        // });
      }
    } catch (error: any) {
      fetchNewPermission(
        org_key,
        taskListCmsData?.lbl_error_message_permission_denied,
      );
    }
  };

  useEffect(() => {
    if (typeof sectionDetails.section_id === 'string') {
      setIsEditingSectionName(true);
    } else {
      setIsEditingSectionName(false);
    }
  }, [sectionDetails.section_id]);

  useEffect(() => {
    if (isEditingSectionName) {
      setSectionName(taskListDetails?.section_name ?? '');
    }
  }, [isEditingSectionName]);

  useEffect(() => {
    if (!isEditingSectionName) {
      setSectionName(taskListDetails?.section_name ?? '');
    }
  }, [taskListDetails?.section_name]);

  // useEffect(() => {
  //   if (
  //     isSimpleTaskListSectionInViewport &&
  //     !taskListDetails?.task_list_details
  //   ) {
  //     /* Comment the if clause for the PRODUCTION release on 21st Feb 2024 and empty the dependency array */
  //     if (typeof sectionDetails.section_id !== "string") {
  //       getSectionDetails();
  //     } else {
  //       setIsLoadingTaskListDetails(false);
  //     }
  //   }
  // }, [simpleTaskListSection, isSimpleTaskListSectionInViewport]);
  const initializeProjectSettings = () => {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails?.organization_info)
      ?.organization_id!;

    storeSectionListStatusInLocalStorage(
      sectionDetails,
      userDetails.user_id.toString(),
    );
    let collapseState = findCollapseStateFromStorage(
      sectionDetails,
      userDetails.user_id.toString(),
      projectId.toString(),
      orgId.toString(),
    );
    setSectionToggleState((prev) =>
      collapseState && collapseState.collapsed ? 'collapsed' : 'expanded',
    );
    if (collapseState && collapseState.view) {
      setCurrentTaskListView(collapseState.view);
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId.toString(),
        orgId.toString(),
        undefined,
        collapseState.view,
        undefined,
      );
    } else {
      setCurrentTaskListView('card');
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId.toString(),
        orgId.toString(),
        undefined,
        'card',
        undefined,
      );
    }
  };

  const toggleSidePanelForCustomField = () => {
    setShowSidePanelForCustomField({
      show: !showSidePanelForCustomField.show,
      fieldMetaData: null,
    });
    addRemoveOverLay();
  };

  const getCustomFields = async () => {
    if (customFields?.length) return;

    try {
      const { data, messageId } = await projectService.getCustomFieldData();
      const list = data?.category_list?.length ? data.category_list : data;
      if (messageId === 1) setCustomFields(list);
    } catch (error) {
      await loggerService.log({
        severity: 'High',
        message: 'Failed to fetch custom fields',
        payload: null,
      });
    }
  };

  useEffect(() => {
    initializeProjectSettings();
    if (typeof sectionDetails.section_id !== 'string') {
      getSectionDetails();
    } else {
      setIsLoadingTaskListDetails(false);
    }
    getCustomFields();
  }, []);

  return (
    <Fragment>
      {contextHolder}
      <div
        className="createNewSecMainContent"
        id={`${SectionTypeForViewPort.SIMPLE_TASK_LIST}-${sectionDetails.section_id}`}
        ref={simpleTaskListSection}
      >
        {isLoadingTaskListDetails ? (
          <Skeleton active loading={isLoadingTaskListDetails} />
        ) : (
          <Fragment>
            <div className="taskNameHeadingPane">
              <div className="itemNameBlk pncFeedbackListHeader">
                <div
                  className="nameOuterBlk widgetBlockActive"
                  style={{
                    backgroundColor:
                      colorChanger(
                        -0.5,
                        projectColor.trim(),
                        undefined,
                        true,
                      ) ?? projectColor,
                  }}
                >
                  {/*<Tooltip
                    title={taskListCmsData?.lbl_section_view_toggle_tooltip}
                    color={'#2E364C'}
                    placement="bottom"
                  >
                     <span
                      className={`sidePanelIcon ${
                        sectionToggleState === 'expanded'
                          ? 'arrow-down'
                          : 'arrow-right'
                      }`}
                      color={'#2E364C'}
                      onClick={handleSectionToggle}
                      onKeyDown={() => {}}
                    /> 
                  </Tooltip>*/}
                  {isEditingSectionName &&
                  projectDetails?.is_archived === false ? (
                    <Rbac
                      allowedPermissions={[
                        ERbacPermissions.PROJECT_SECTION_EDIT,
                      ]}
                      project_role_id={projectDetails?.associated_role_id}
                    >
                      <div className="auto-grow-input">
                        <input
                          className="section-text-input"
                          placeholder={
                            taskListCmsData?.lbl_new_section_default_name
                          }
                          onBlur={sectionNameOnBlurHandler}
                          onChange={sectionNameChangeHandler}
                          onKeyDown={saveOnEnter}
                          value={sectionName}
                          autoFocus={true}
                          maxLength={50}
                        />
                        <span className="auto-grow-input-hidden">
                          {sectionName}
                        </span>
                      </div>
                    </Rbac>
                  ) : (
                    <span
                      className="section-text-span"
                      onClick={() => {
                        if (projectDetails?.is_archived === false) {
                          hasPermissions(
                            [ERbacPermissions.PROJECT_SECTION_EDIT],
                            projectDetails?.associated_role_id,
                          ) && setIsEditingSectionName(true);
                        }
                      }}
                      onKeyDown={() => {}}
                    >
                      {sectionName?.trim() === ''
                        ? taskListDetails.section_name
                        : sectionName}
                    </span>
                  )}
                  {!isEditingSectionName &&
                  projectDetails?.sections &&
                  taskListDetails.is_private ? (
                    <span className="cmnIcon sectionPrivacy lock "></span>
                  ) : null}
                </div>
                {!isEditingSectionName &&
                projectDetails?.sections &&
                !forPnc ? (
                  <MeatBallMenu
                    sectionType="task-list"
                    sectionDetails={taskListDetails}
                    setSectionDetails={setTaskListDetails}
                    setShowSidePanel={setShowSidePanel}
                    // socket={socket}
                    getSectionDetails={getSectionDetails}
                    sectionList={projectDetails?.sections}
                    changeTaskListView={changeTaskListView}
                    refDiv={refDiv}
                  />
                ) : null}
                {sectionDetails && forPnc && (
                 
                    <Tooltip
                      title={
                        pncFeedbackSpecificCmsData?.lbl_tooltips
                          .lbl_list_view_url
                      }
                      color={'#2E364C'}
                      placement="right"
                    >
                      <a
                        href={`${sectionDetails?.page_url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="material-icons-outlined">
                          language
                        </span>
                      </a>
                    </Tooltip>
                  
                )}
                {/* <button
                  onClick={toggleSidePanelForCustomField}
                  className="btnStyle1"
                  aria-label="create new project"
                >
                  Create Custom field
                </button> */}
              </div>
              <div
                className="tblControls"
                id={`table-section-filter-container-${taskListDetails?.section_id}`}
              ></div>

              <SectionFilters
                sectionDetails={taskListDetails}
                setSectionDetails={setTaskListDetails}
                getSectionDetails={getSectionDetails}
              />
            </div>
            {forPnc && (
              <Rbac
                allowedPermissions={[
                  ERbacPermissions.PROJECT_SECTION_TASK_VIEW,
                ]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <TaskListDetails2
                  customfieldCmsData={customfieldCmsData}
                  isTaskEmpty={isTaskEmpty}
                  setIsTaskEmpty={setIsTaskEmpty}
                  taskDetails={taskListDetails}
                  setTaskListDetails={setTaskListDetails}
                  sectionToggleState={sectionToggleState}
                  // socket={socket}
                  setShowSidePanelForCustomField={() => {}}
                  getSectionDetails={getSectionDetails}
                  // currentSection={currentSection}
                  // setCurrentSection={setCurrentSection}
                  forPnc={forPnc}
                />
              </Rbac>
            )}
            {currentTaskListView === TaskListView.LIST && !forPnc && (
              <Rbac
                allowedPermissions={[
                  ERbacPermissions.PROJECT_SECTION_TASK_VIEW,
                ]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <TaskListDetails2
                  customfieldCmsData={customfieldCmsData}
                  isTaskEmpty={isTaskEmpty}
                  setIsTaskEmpty={setIsTaskEmpty}
                  taskDetails={taskListDetails}
                  setTaskListDetails={setTaskListDetails}
                  sectionToggleState={sectionToggleState}
                  // socket={socket}
                  setShowSidePanelForCustomField={() => {}}
                  getSectionDetails={getSectionDetails}
                  // currentSection={currentSection}
                  // setCurrentSection={setCurrentSection}
                  forPnc={forPnc}
                />
              </Rbac>
            )}
            {currentTaskListView === TaskListView.CARD && !forPnc && (
              <Rbac
                allowedPermissions={[
                  ERbacPermissions.PROJECT_SECTION_TASK_VIEW,
                ]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <TaskListCards
                  setIsTaskEmpty={setIsTaskEmpty}
                  taskDetails={taskListDetails}
                  setTaskListDetails={setTaskListDetails}
                  sectionToggleState={sectionToggleState}
                  // socket={socket}
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                />
              </Rbac>
            )}
          </Fragment>
        )}
      </div>
      {showSidePanel ? (
        <RightSideDrawerForCustomizeStatusUpdate
          sectionDetails={taskListDetails}
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          setSectionDetails={setTaskListDetails}
        />
      ) : null}
      {showSidePanelForCustomField.show ? (
        <CreateCustomField
          pageSpecificDataState={taskListDetails}
          showSidePanel={showSidePanelForCustomField.show}
          setShowSidePanel={setShowSidePanelForCustomField}
          selectedTemplate={{}}
          fieldMetaData={showSidePanelForCustomField.fieldMetaData}
          getSectionDetails={getSectionDetails}
        />
      ) : null}
    </Fragment>
  );
};

export default TaskList;
