import { CloseOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import {
  AttachmentIcon,
  NumberIcon,
  PriorityIcon,
} from 'components/sharedComponents/CustomAppIcons';
import { ICustomFieldModalAttributes } from 'models/interface';
import {
  CustomField,
  CustomFieldCategory,
} from 'models/interface/customField.interface';
import React, { useState } from 'react';
import singleSelectIcon from '../../../../../../assets/images/single-select.svg';
import multiSelectIcon from '../../../../../../assets/images/multiSelect.svg';
import shortText from '../../../../../../assets/images/short-text.svg';
import dueDate from '../../../../../../assets/images/date.svg';
import effort from '../../../../../../assets/images/effort.svg';

interface AddCustomFieldProps {
  options: CustomFieldCategory[];
  onClick: (option: CustomField) => void;
  customfieldCmsData?: ICustomFieldModalAttributes;
  onOpen?: () => void;
}

const getIcon = (fieldId: number) => {
  switch (fieldId) {
    case 1:
      return <PriorityIcon />;
    case 2:
      return <NumberIcon />;
    case 3:
      return <AttachmentIcon />;
    case 4:
      return <img src={singleSelectIcon} />;
    case 5:
      return <img src={multiSelectIcon} />;
    case 6:
      return <img src={shortText} />;
    case 7:
      return <img src={dueDate} />;
    case 8:
      return <img src={effort} />;
    default:
      return null;
  }
};

const AddCustomField: React.FC<AddCustomFieldProps> = ({
  options,
  onClick,
  customfieldCmsData,
  onOpen,
}) => {
  const [visible, setVisible] = useState(false);
  const handleOptionClick = (option: CustomField) => {
    let selectedOption = { ...option };
    if (option.field_type_id === 2) {
      selectedOption.default_meta = {
        ...(option?.default_meta ?? {}),
      };
    }
    onClick(selectedOption);
    setVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
  };
  const getLocalizedCategoryName = (option: CustomFieldCategory) => {
    const foundIndex =
      customfieldCmsData?.lbl_add_column_popup_options?.findIndex(
        (item: any) => item.category_id === option.category_id,
      ) ?? -1;
    if (foundIndex !== -1) {
      const item = customfieldCmsData?.lbl_add_column_popup_options[foundIndex];
      return item?.category_name;
    }
    return option?.category_name;
  };
  const content = (
    <>
      {options.map((option) => {
        return (
          <div key={Math.random().toString()}>
            <div className="innerHeading">
              {getLocalizedCategoryName(option)}
            </div>
            <div className="addColRow">
              {option.details.map((field, index) => {
                const fieldId = field?.field_type_id || 1;

                return (
                  <div className="addCol" key={index}>
                    <Tooltip title={field?.default_meta?.description}>
                      <Button
                        onClick={() => handleOptionClick(field)}
                        icon={getIcon(fieldId)}
                      >
                        {field.field_type_name}
                      </Button>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );

  return (
    <Popover
      content={content}
      title={
        <>
          {customfieldCmsData?.lbl_add_column_tooltip}
          {/* <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={handleClose}
            style={{ float: 'right' }}
          /> */}
          <span className="material-icons-outlined modal-close" onClick={handleClose} >close</span>
        </>
      }
      trigger="click"
      open={visible}
      onOpenChange={setVisible}
      overlayClassName="addCustomFieldPopover"
      placement="topLeft"
    >
      <Tooltip
        title={customfieldCmsData?.lbl_add_column_tooltip}
        placement={'left'}
        color={'#2E364C'}
      >
        <span
          className="addPlusSolid"
          onKeyDown={() => { }}
          onClick={() => {
            onOpen?.();
            setVisible(true);
          }}
        ></span>
      </Tooltip>
    </Popover>
  );
};

export default AddCustomField;
