import { Dropdown, Menu, Tooltip } from 'antd';
import {
  IGridList,
  IGridListBlockDetail,
  IProjectState,
  IGridListTaskDetail,
} from 'models/interface';
import React, { useMemo, FC, useState, ReactNode } from 'react';
import { useProjectDashboardStore } from 'stores';
import { getUserGravatar, groupGridBlock } from 'utils/commonFunctions';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useRbac } from 'auth/rbac/rbac';
import dayjs from 'dayjs';

// Images
import BlockIcon from 'assets/e-images/block-icon.svg';
import TodoIcon from 'assets/e-images/todo-icon.svg';
import DoneIcon from 'assets/e-images/done-icon.svg';
import TrashIcon from 'assets/images/trash-action-icon.svg';
import UserIcon from 'assets/images/user-action-icon.svg';
import CalendarIcon from 'assets/images/calendar-action-icon.svg';
import StatusIcon from 'assets/images/status-action-icon.svg';

import BlockDueDate from '../Block/BlockWithCompleteDetails/BlockDueDate';
import MessageThread from '../../MessageThread';
import { SectionTypeMessages } from 'models/enums/messages';
import { getThreadName } from '../Block/BlockWithCompleteDetails/BlockWithCompleteDetailsWrapper';
import SharedAssigneeDropDown from 'components/sharedComponents/SharedAssigneeDropDown/SharedAssigneeDropDown';
import { ContextTypes } from 'models/enums/constants';

const { SubMenu } = Menu;

interface CustomMenuProp {
  label: string;
  key: number;
  children?: any[] | ReactNode;
}

const ActionDropdown: FC<{
  blockId?: number;
  blockDetails: IGridListBlockDetail;
  gridListDetails: IGridList;
  sectionType?: 'block' | 'stage' | 'task';
  index?: number;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
}> = ({
  blockId,
  gridListDetails,
  setGridListDetails,
  updateBlock,
  blockDetails,
  sectionType = 'block',
  index,
}) => {
  // Store
  const { taskListCmsData, taskStatusMaster, projectDetails, gridListCmsData } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();

  // States
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();

  const defaultStatusItems = [
    {
      label: (
        <div className="customDropDownItem">
          <span className="cmnIcon">
            <img src={TodoIcon} alt="UserIcon" />
          </span>
          {taskListCmsData?.task_status_options[0].status_name}
        </div>
      ),
      key: 1,
      stringLabel: taskListCmsData?.task_status_options[0].status_name,
      className: 'todo',
    },
    {
      label: (
        <div className="customDropDownItem">
          <span className="cmnIcon">
            <img src={DoneIcon} alt="UserIcon" />
          </span>
          {taskListCmsData?.task_status_options[2].status_name}
        </div>
      ),
      key: 3,
      stringLabel: taskListCmsData?.task_status_options[2].status_name,
      className: 'done',
    },
    {
      label: (
        <div className="customDropDownItem">
          <span className="cmnIcon">
            <img src={BlockIcon} alt="UserIcon" />
          </span>
          {taskListCmsData?.task_status_options[3].status_name}
        </div>
      ),
      key: 4,
      stringLabel: taskListCmsData?.task_status_options[3].status_name,
      className: 'blocked',
    },
  ];

  // Functions
  const setStatus = (statusCode: number) => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;

      let targetBlock = blockDetails.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;
      let targetBlockIndex = blockDetails.findIndex(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;

      let _status_id = statusCode;
      const isOverDue =
        dayjs(targetBlock.due_date).diff(
          dayjs(new Date().setHours(0, 0, 0, 0)),
          'days',
        ) < 0;

      if (taskStatusMaster && _status_id > taskStatusMaster?.length) {
        _status_id = isOverDue ? 2 : 1;
      }

      if (_status_id === 2) {
        _status_id = 3;
      }

      if (statusCode === 1 && isOverDue) _status_id = 2;

      targetBlock.block_status_id = _status_id;
      blockDetails.splice(targetBlockIndex, 1, targetBlock);

      let completedBlockCount = blockDetails.filter(
        (ele: IGridListBlockDetail) =>
          ele.task_id === targetBlock.task_id && ele.block_status_id === 3,
      )!;

      let taskDetails = gridListDetails.grid_list_details?.task_details!;

      if (taskDetails) {
        let targetTask = taskDetails?.find(
          (ele: IGridListTaskDetail) => ele.task_id === targetBlock.task_id,
        )!;
        let targetTaskIndex = taskDetails?.findIndex(
          (ele: IGridListTaskDetail) => ele.task_id === targetBlock.task_id,
        )!;
        targetTask.completed_block_count = completedBlockCount.length;
        taskDetails.splice(targetTaskIndex, 1, targetTask);
      }

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          block_details: groupGridBlock(
            blockDetails,
            gridListDetails.grid_list_details!.task_details!,
          ) as Array<IGridListBlockDetail[]>,
          task_details: taskDetails,
        },
      } as IGridList);

      updateBlock(targetBlock);
    }
  };

  const getStatusInfo = (
    status: any[],
    block: IGridListBlockDetail,
  ): { stringLabel: string; className?: string; key: number } => {
    const overDue = {
      stringLabel:
        taskListCmsData?.task_status_options[1].status_name ?? 'Overdue',
      className: 'overdue',
      key: 0,
    };

    if (block.block_status_id === 2) return overDue;

    const info = status.find((i) => i.key === block.block_status_id);

    if (info) return info;

    return {
      stringLabel: taskListCmsData?.lbl_task_status_header || 'Status',
      key: 0,
    };
  };

  const getAssigneeName = (block: IGridListBlockDetail) => {
    if (block?.assignee_inactive) {
      return block?.assignee_email?.length &&
        block?.assignee_email?.length > 9 ? (
        <Tooltip title={block?.assignee_email}>
          {block?.assignee_email?.slice(0, 9) + '...'}
        </Tooltip>
      ) : (
        block.assignee_email
      );
    } else if (block?.assignee_name) {
      return block.assignee_name.length > 9 ? (
        <Tooltip title={block.assignee_name}>
          {block.assignee_name.slice(0, 9) + '...'}
        </Tooltip>
      ) : (
        block.assignee_name
      );
    }
    return taskListCmsData?.lbl_task_assignee_header ?? 'Assignee';
  };

  const getAssigneeAvt = (block: IGridListBlockDetail) => {
    if (!block?.assignee_inactive && block?.assignee_name) {
      if (block?.assignee_profile_picture)
        return <img src={block.assignee_profile_picture} alt="UserAvatar" />;
      else
        return (
          <div className="initalProfile">
            {getUserGravatar(block.assignee_name ?? '')}
          </div>
        );
    } else if (block?.assignee_email && block?.assignee_inactive) {
      return <span className="userAvtr userAvtrBlank compactViewBlankAvtr" />;
    }

    return <img src={UserIcon} alt="UserIcon" />;
  };

  const getDueDate = (block: IGridListBlockDetail) => {
    return block?.due_date
      ? dayjs(block.due_date).format('MMM DD')
      : taskListCmsData?.lbl_task_due_date_header ?? 'Due Date';
  };

  // Memos
  const statusItems = useMemo(() => {
    if (blockDetails.block_status_id === 2)
      return defaultStatusItems.filter((item) => item.key !== 1);

    return defaultStatusItems.filter(
      (item) => item.key !== blockDetails.block_status_id,
    );
  }, [blockDetails, gridListDetails]);

  const items: any = useMemo(() => {
    const defaultItems = [
      {
        label: (
          <div
            className={
              'customDropDownItem ' +
              (blockDetails?.assignee_profile_picture ? 'userPic' : '')
            }
          >
            <span className="cmnIcon">{getAssigneeAvt(blockDetails)}</span>
            {getAssigneeName(blockDetails)}
          </div>
        ),
        key: 1,
        children: (
          <SharedAssigneeDropDown
            blockDetails={blockDetails}
            showDropDown={1}
            setShowDropDown={() => {}}
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            assigneeDetails={{
              assignee_id: blockDetails.assignee_id,
              assignee_name: blockDetails.assignee_name,
              assignee_email: blockDetails.assignee_email,
              assignee_profile_picture: blockDetails.assignee_profile_picture,
            }}
            updateBlock={updateBlock}
            contextType={ContextTypes.GRIDLIST}
          />
        ),
        disabled:
          !hasPermissions(
            [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
            projectDetails?.associated_role_id,
          ) || projectDetails?.is_archived === true,
      },
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={CalendarIcon} alt="UserIcon" />
            </span>
            {getDueDate(blockDetails)}
          </div>
        ),
        key: 3,
        children: (
          <BlockDueDate
            blockDetails={blockDetails}
            showCalender={1}
            setShowCalender={() => {}}
            blockId={blockDetails.block_id}
            dueDate={blockDetails.due_date}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
          />
        ),
        disabled:
          !hasPermissions(
            [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
            projectDetails?.associated_role_id,
          ) || projectDetails?.is_archived === true,
      },
      {
        label: (
          <div
            className={
              'customDropDownItem ' +
              getStatusInfo(defaultStatusItems, blockDetails)?.className
            }
          >
            <span className="cmnIcon">
              <img src={StatusIcon} alt="UserIcon" />
            </span>
            {getStatusInfo(defaultStatusItems, blockDetails)?.stringLabel}
          </div>
        ),
        key: 4,
        children: statusItems,
        disabled: projectDetails?.is_archived === true,
      },
      {
        label: <></>,
        key: 5,
        children: <></>,
      },
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={TrashIcon} alt="UserIcon" />
            </span>
            {gridListCmsData?.lbl_delete_task_modal_header}
          </div>
        ),
        key: 6,
      },
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={TrashIcon} alt="UserIcon" />
            </span>
            {gridListCmsData?.lbl_delete_stage_modal_header}
          </div>
        ),
        key: 7,
      },
      // Temp removed
      // {
      //   label: (
      //     <div className="customDropDownItem">
      //       <span className="cmnIcon">
      //         <img src={RefreshIcon} alt="UserIcon" />
      //       </span>
      //       {gridListCmsData?.lbl_block_reset ?? "Reset"}
      //     </div>
      //   ),
      //   key: 8,
      // },
    ];

    if (sectionType === 'stage')
      return defaultItems.filter((item) => [1, 6].includes(item.key));
    if (sectionType === 'task')
      return defaultItems.filter((item) => [1, 7].includes(item.key));

    // Block
    if (!blockDetails?.assignee_id && !blockDetails?.due_date)
      return defaultItems.filter((item) => [1, 3, 8].includes(item.key));

    return defaultItems.filter((item) => [1, 2, 3, 4, 5, 8].includes(item.key));
  }, [blockDetails, gridListDetails]);

  const handleClickOption = (e: any) => {
    if (e?.key === 4 && e?.children) {
      hasPermissions(
        [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_STATUS_EDIT],
        projectDetails?.associated_role_id,
      ) && setStatus(Number(e?.children?.key));
    }
  };

  // Components
  const CustomMenu = ({ menu }: { menu: CustomMenuProp[] }) => {
    return (
      <Menu triggerSubMenuAction="click">
        {menu?.map((item: any) => {
          if (item?.children?.length > 0) {
            return (
              <SubMenu
                title={item.label}
                key={item.key}
                popupClassName="customSubMenu"
                disabled={projectDetails?.is_archived === true ? true : false}
              >
                {item.children.map((child: CustomMenuProp) => (
                  <Menu.Item
                    onClick={() =>
                      handleClickOption({
                        ...item,
                        children: child,
                      })
                    }
                    key={child.key}
                  >
                    {child.label}
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }

          if (item.key === 5) {
            return (
              <MessageThread
                componentId="gridBlk"
                sectionId={gridListDetails!.section_id}
                taskId={blockDetails.block_id}
                compactView={true}
                sectionType={SectionTypeMessages.GRID_LIST_BLOCK}
                threadName={getThreadName(gridListDetails, blockDetails) || ''}
                sectionName={gridListDetails!.section_name!}
                taskDetails={blockDetails}
              />
            );
          }

          if (React.isValidElement(item?.children) && item.key !== 5) {
            return (
              <SubMenu
                title={item.label}
                key={item.key}
                popupClassName={
                  'customSubMenu ' + (item?.key === 5 ? 'twoLavelMsgPopup' : '')
                }
                disabled={item.disabled || projectDetails?.is_archived === true}
              >
                <Menu.Item key={item.key}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.children}
                  </div>
                </Menu.Item>
              </SubMenu>
            );
          }

          return (
            <Menu.Item key={item.key}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {item.label}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <Dropdown
      dropdownRender={() => {
        return <CustomMenu menu={items} />;
      }}
      placement="bottomLeft"
      trigger={['click']}
    >
      <div className="gridMore">More</div>
    </Dropdown>
  );
};

export default ActionDropdown;
