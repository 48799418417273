import { Checkbox, Collapse, message, Skeleton } from 'antd';
import classNames from 'classnames';
import { ButtonLoader } from 'components/sharedComponents/Loaders/ButtonLoader';
import { IUserState } from 'models/interface';
import { FC, useEffect, useState } from 'react';
import organizationService from 'services/OrganizationService/Organization.service';
import { useUserStore } from 'stores';

const { Panel } = Collapse;

type TBaseOfFields = {
  display_rank: number;
  option_id: number;
  name: string;
  read_only: boolean;
  default_checked: boolean;
};

type TMasterOfSettings = {
  display_rank: number;
  mapping_id: number;
  name: string;
  read_only: boolean;
  default_checked: boolean;
  values?: null | TBaseOfFields[];
};

const TeamOptionalFields: FC<{
  pageCms?: any;
  usedOutsideOfSettings?: boolean;
  setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setOfSettings?: React.Dispatch<React.SetStateAction<any>>;
  projectOfSettings?: any;
  setShouldCallBackendOf?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  pageCms,
  usedOutsideOfSettings = false,
  setIsDisabled,
  setOfSettings,
  projectOfSettings,
  setShouldCallBackendOf,
}) => {
  const [masterOfSettings, setMasterOfSettings] = useState<TMasterOfSettings[]>(
    [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false),
    [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const [orgOfJson, setOrgOfJson] = useState<TMasterOfSettings[]>([]); //of_settings of the organization
  const [panel, setPanel] = useState<TMasterOfSettings[]>([]); // extracted status and priority to render them as panel

  const [orgOfFields, setOrgOfFields] = useState<number[] | undefined>([]); // organization's optional fields options as an array
  const [orgStatusFields, setOrgStatusFields] = useState<number[] | undefined>(
    [],
  ); // organization's sub-options of Status OF as an array
  const [orgPriorityFields, setOrgPriorityFields] = useState<
    number[] | undefined
  >([]); // organization's sub-options of Priority OF as an array

  const [selectedOfFields, setSelectedOfFields] = useState<any[]>([]); //selected optional fields options as an array
  const [selectedStatusFields, setSelectedStatusFields] = useState<number[]>(
    [],
  ); // selected sub-options of Status OF as an array
  const [selectedPriorityFields, setSelectedPriorityFields] = useState<
    number[]
  >([]); // selected sub-options of Priority OF as an array

  const { organizations, currentOrgKey } = useUserStore(
    (state: IUserState) => state,
  );

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (usedOutsideOfSettings === true) {
      let updatedFields = buildPayload(
        selectedOfFields,
        selectedStatusFields,
        selectedPriorityFields,
      );
      setIsDisabled &&
        setIsDisabled(!isChangeDetected(orgOfJson, updatedFields));
      setShouldCallBackendOf &&
        setShouldCallBackendOf(isChangeDetected(orgOfJson, updatedFields));
      setOfSettings && setOfSettings(updatedFields);
    }
  }, [selectedOfFields, selectedStatusFields, selectedPriorityFields]);

  const getMasterOfSettings = async () => {
    try {
      setIsLoading(true);
      const response = await organizationService.getMasterOfSettings();
      if (usedOutsideOfSettings && projectOfSettings) {
        setOrgOfJson(projectOfSettings);
        let tempOrgFields: number[] =
          projectOfSettings
            ?.map((e: TMasterOfSettings) => e?.mapping_id)
            .sort() ?? [];
        setOrgOfFields(tempOrgFields.length ? tempOrgFields : [1, 9]);
        setSelectedOfFields((prev) =>
          tempOrgFields.length
            ? [...new Set([...prev, ...(tempOrgFields ?? [])].sort())]
            : [1, 9],
        );
      } else {
        const orgResponse = await organizationService.getOrgOfSettings({
          org_id: Number(
            organizations?.filter((e: any) => e.org_key === currentOrgKey)?.[0]
              .organization_id,
          ),
        });

        if (orgResponse.messageId === 1) {
          setOrgOfJson(orgResponse?.data);
          let tempOrgFields: number[] =
            orgResponse?.data
              ?.map((e: TMasterOfSettings) => e?.mapping_id)
              .sort() ?? [];
          setOrgOfFields(tempOrgFields.length ? tempOrgFields : [1, 9]);
          setSelectedOfFields((prev) =>
            tempOrgFields.length
              ? [...new Set([...prev, ...(tempOrgFields ?? [])].sort())]
              : [1, 9],
          );
          !orgResponse.data && setSelectedPriorityFields([2, 3]);
          !orgResponse.data && setSelectedStatusFields([1, 2, 3]);
        } else {
          setOrgOfFields([]);
          setOrgStatusFields([]);
          setOrgPriorityFields([]);
        }
      }

      if (response.messageId === 1) {
        setMasterOfSettings(response.data);
        setPanel(
          response.data?.filter((element: TMasterOfSettings) => {
            if (element?.mapping_id === 1 || element?.mapping_id == 9) {
              return element;
            }
          }),
        );
        return;
      }
      setMasterOfSettings([]);
      setPanel([]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isChangeDetected = (originalArray: any[], newArray: any[]) => {
    if (originalArray?.length !== newArray?.length) {
      return true;
    }

    for (let i = 0; i < originalArray.length; i++) {
      const originalItem = originalArray[i];
      const newItem = newArray[i];

      if (originalItem.mapping_id !== newItem.mapping_id) {
        return true;
      }

      if (originalItem.values === null && newItem.values === null) {
        continue;
      }

      if (
        (originalItem.values === null && newItem.values !== null) ||
        (originalItem.values !== null && newItem.values === null)
      ) {
        return true;
      }

      if (originalItem.values && newItem.values) {
        const originalOptionIds = originalItem.values
          .map((v: any) => v.option_id)
          .sort();
        const newOptionIds = newItem.values.map((v: any) => v.option_id).sort();

        if (
          JSON.stringify(originalOptionIds) !== JSON.stringify(newOptionIds)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const updateOrgOfSettings = async () => {
    let of_settings = buildPayload(
      selectedOfFields,
      selectedStatusFields,
      selectedPriorityFields,
    );
    const currentOrganization = organizations?.filter(
      (e: any) => e.org_key === currentOrgKey,
    )?.[0].organization_id;
    if (currentOrganization) {
      try {
        setIsSubmiting(true);
        const response = await organizationService.updateOrgOfSettings({
          org_id: Number(currentOrganization),
          of_settings,
        });
        if (response.messageId === 1) {
          setOrgOfFields(selectedOfFields);
          setOrgPriorityFields(selectedPriorityFields);
          setOrgStatusFields(selectedStatusFields);
          messageApi.success({
            content: pageCms.lbl_update_of_message.success,
            duration: 3,
          });
        } else {
          messageApi.error({
            content: pageCms.lbl_update_of_message.failed,
            duration: 3,
          });
        }
      } catch (error) {
        messageApi.error({
          content: pageCms.lbl_update_of_message.failed,
          duration: 3,
        });
      } finally {
        setIsSubmiting(false);
      }
    }
  };

  const getFieldName = (element: TMasterOfSettings) => {
    switch (element.mapping_id) {
      case 1:
        return pageCms?.lbl_task_status_header;
      case 5:
        return pageCms?.lbl_assigned_on_header;
      case 6:
        return pageCms?.lbl_assigned_by_header;
      case 7:
        return pageCms?.lbl_last_updated_on_header;
      case 8:
        return pageCms?.lbl_last_updated_by_header;
      case 9:
        return pageCms?.lbl_task_priority_header;

      default:
        break;
    }
  };

  const getSubOptionsName = (
    element: TMasterOfSettings,
    subElement: TBaseOfFields,
  ) => {
    if (element.mapping_id === 1) {
      let filteredObj = pageCms?.task_status_options?.filter(
        (e: any) => e.status_id == subElement.option_id,
      );
      return filteredObj[0]?.status_name;
    }
    if (element.mapping_id === 9) {
      let filteredObj = pageCms?.task_priority_options?.filter(
        (e: any) => e.priority_id == subElement.option_id,
      );
      return filteredObj[0]?.priority_name;
    }
  };

  const insertOfFields = (e: any) => {
    let temp = [...selectedOfFields];
    let index = temp.indexOf(e.mapping_id);
    if (index === -1) {
      temp.push(e.mapping_id);
    } else {
      temp.splice(index, 1);
    }
    temp = temp?.sort();
    if (!temp.includes(9)) {
      setSelectedPriorityFields([]);
    } else {
      setSelectedPriorityFields((prev: any) => [
        ...new Set([...prev, 2, 3].sort()),
      ]);
    }
    setSelectedOfFields([...temp]);
  };

  const insertStatusOfFields = (e: any) => {
    let temp = [...selectedStatusFields];
    let index = temp.indexOf(e.option_id);
    if (index === -1) {
      temp.push(e.option_id);
    } else {
      temp.splice(index, 1);
    }
    temp = temp.sort();
    setSelectedStatusFields([...temp]);
  };

  const insertPriorityOfFields = (e: any) => {
    let temp = [...new Set([...selectedPriorityFields, 2, 3].sort())];
    let index = temp.indexOf(e.option_id);
    if (index === -1) {
      temp.push(e.option_id);
    } else {
      temp.splice(index, 1);
    }
    temp = temp?.sort();
    if (temp.length > 0) {
      setSelectedOfFields((prev: any) => [...new Set([...prev, 9]?.sort())]);
    } else {
      let tempSelectedFields = [...selectedOfFields];
      let index = tempSelectedFields.indexOf(9);
      index !== -1 && setSelectedOfFields([...temp.splice(index, 1).sort()]);
    }
    setSelectedPriorityFields([...temp]);
  };

  const checkEnable = (
    element: TMasterOfSettings,
    subElement: TBaseOfFields,
  ) => {
    if (element.mapping_id === 1 && selectedOfFields.includes(1)) {
      return selectedStatusFields.includes(subElement.option_id);
    }
    if (element.mapping_id === 9 && selectedOfFields.includes(9)) {
      return selectedPriorityFields.includes(subElement.option_id);
    }
    return false;
  };

  const disbleUpdateButton = (): boolean => {
    return (
      orgOfFields?.sort().join('') === selectedOfFields?.sort().join('') &&
      orgStatusFields?.sort().join('') ===
        selectedStatusFields?.sort().join('') &&
      orgPriorityFields?.sort().join('') ===
        selectedPriorityFields?.sort().join('')
    );
  };

  const defaultOfChecked = (
    masterElement: TMasterOfSettings,
    selectedElement: any,
  ) => {
    if (!selectedElement.length && !orgOfFields?.length) {
      return masterElement.read_only || masterElement.default_checked;
    } else {
      return selectedElement.includes(masterElement.mapping_id);
    }
  };

  const checkIntermediate = (element: TMasterOfSettings) => {
    switch (element.mapping_id) {
      case 1:
        return (
          selectedStatusFields.length > 0 &&
          selectedStatusFields.length < element.values?.length!
        );
      case 9:
        return (
          (orgOfFields?.includes(9) || selectedOfFields.includes(9)) &&
          selectedPriorityFields.length > 0 &&
          selectedPriorityFields.length < element.values?.length!
        );
      default:
        return false;
    }
  };

  const reset = () => {
    setMasterOfSettings([]);
    setOrgOfJson([]);
    setPanel([]);

    setSelectedOfFields([1]);
    setSelectedStatusFields([1, 2, 3]);
    setSelectedPriorityFields([2, 3]);
    setOrgOfFields([]);
    setOrgStatusFields([]);
    setOrgPriorityFields([]);
  };

  useEffect(() => {
    getMasterOfSettings();

    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    let tempStatus = [...selectedStatusFields];
    let priorityStatus = [...selectedPriorityFields];

    orgOfJson?.forEach((e: TMasterOfSettings) => {
      if (e.mapping_id === 1) {
        let temp = e?.values?.map((el: TBaseOfFields) => el.option_id);
        let uniqueArr = [...new Set(temp), ...new Set(tempStatus)];
        setSelectedStatusFields([...new Set(uniqueArr)]);
        setOrgStatusFields(temp);
      }
      if (e.mapping_id === 9) {
        let temp = e?.values?.map((el: TBaseOfFields) => el.option_id);
        let uniqueArr = [...new Set(temp), ...new Set(priorityStatus)];
        setSelectedPriorityFields([...new Set(uniqueArr)]);
        setOrgPriorityFields(temp);
      }
    });
  }, [orgOfJson]);

  const renderPanel = (element: TMasterOfSettings) => {
    return (
      <Panel
        header={
          <Checkbox
            indeterminate={checkIntermediate(element)}
            onChange={() => {
              insertOfFields(element);
            }}
            checked={defaultOfChecked(element, selectedOfFields)}
            disabled={element.read_only}
          >
            {getFieldName(element)}
          </Checkbox>
        }
        key={element.mapping_id}
        collapsible="disabled"
        showArrow={false}
      >
        <ul className="genericCheckBoxWrp">
          {element?.values?.map((el: TBaseOfFields) => {
            return (
              <li key={`${element.mapping_id}-${el.option_id}`}>
                <Checkbox
                  onChange={() => {
                    element.mapping_id === 1 && insertStatusOfFields(el);
                    element.mapping_id === 9 && insertPriorityOfFields(el);
                  }}
                  checked={checkEnable(element, el)}
                  disabled={el.read_only}
                >
                  {getSubOptionsName(element, el)}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </Panel>
    );
  };

  return (
    <Skeleton loading={isLoading} active>
      {contextHolder}
      <div className="optionalFields">
        <h3>{pageCms?.lbl_optional_fields_header}</h3>
        <ul className="genericCheckBoxWrp">
          {masterOfSettings?.map((element: TMasterOfSettings) => {
            if (element.mapping_id !== 9 && element.mapping_id !== 1) {
              return (
                <li key={`${element.name}-${element.mapping_id}`}>
                  <Checkbox
                    onChange={() => {
                      insertOfFields(element);
                    }}
                    checked={defaultOfChecked(element, selectedOfFields)}
                    disabled={element.read_only}
                  >
                    {getFieldName(element)}
                  </Checkbox>
                </li>
              );
            }
          })}
        </ul>
        <Collapse defaultActiveKey={[1, 9]} className="checkboxGroup">
          {panel.map((element: TMasterOfSettings) => renderPanel(element))}
        </Collapse>
        {!usedOutsideOfSettings && (
          <div className="btnPart">
            <button
              className={classNames('btnStyle1 buttonLoader', {
                loadingInProgress: isSubmiting,
              })}
              onClick={updateOrgOfSettings}
              disabled={disbleUpdateButton() || isSubmiting}
            >
              {isSubmiting ? (
                <ButtonLoader />
              ) : (
                pageCms?.btn_optional_fields_update
              )}
            </button>
          </div>
        )}
      </div>
    </Skeleton>
  );
};

export default TeamOptionalFields;

const buildPayload = (
  ofFields: any,
  statusSubFields: any,
  prioritySubFields: any,
) => {
  let payload = ofFields.map((e: any) => {
    if (e === 1 || e === 9) {
      return {
        mapping_id: e,
        values:
          e === 1
            ? statusSubFields.map((el: any) => ({ option_id: el }))
            : [
                ...new Set(
                  [...prioritySubFields.map((el: any) => el), 2, 3].sort(),
                ),
              ].map((el: any) => {
                return {
                  option_id: el,
                };
              }),
      };
    }
    return {
      mapping_id: e,
      values: null,
    };
  });
  return payload;
};
