import { Dropdown, MenuProps, message, Space } from 'antd';
import { FC, useRef } from 'react';
import BlockIcon from 'assets/e-images/block-icon.svg';
import TodoIcon from 'assets/e-images/todo-icon.svg';
import DoneIcon from 'assets/e-images/done-icon.svg';
import OverdueIcon from 'assets/e-images/overdue.svg';
import { useFeedbackStore } from 'stores/feedback.store';
import feedbackService from 'services/pncService/feedback.service';
import { useProjectDashboardStore } from 'stores';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import { ITaskList } from 'models/interface';
import dayjs from 'dayjs';

const FeedbackStatus: FC<{
  feedBackStatusValue: number;
  detailsId: number;
  setFeedbackData: any;
  feedbackData: any;
  feedbacId: number;
  taskListDetails: ITaskList | undefined | null;
  setTaskListDetails:
    | React.Dispatch<React.SetStateAction<ITaskList>>
    | undefined
    | null;
}> = ({
  feedBackStatusValue,
  detailsId,
  feedbackData,
  setFeedbackData,
  feedbacId,
  taskListDetails,
  setTaskListDetails,
}) => {
  const { updateFeedbackStatus } = useFeedbackStore((state: any) => state);
  const { projectDetails, taskStatusMaster } = useProjectDashboardStore();
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();
  const originalTaskStatus = useRef(feedBackStatusValue);

  const getStatusIcon = (statusId: number) => {
    switch (statusId) {
      case 1:
        return TodoIcon;
      case 3:
        return DoneIcon;
      case 4:
        return BlockIcon;
      case 2:
        return OverdueIcon;
      default:
        return TodoIcon;
    }
  };

  const statusItems: MenuProps['items'] = taskStatusMaster
    ?.filter((i) => {
      let isExpired = false;
      if (feedbackData[0]?.due_date) {
        isExpired =
          dayjs(feedbackData[0].due_date).diff(
            dayjs(new Date().setHours(0, 0, 0, 0)),
            'days',
          ) < 0;
      }
      if (originalTaskStatus.current === 2 || isExpired) {
        return i.value !== 1 && i.value !== feedBackStatusValue;
      } else return i.value !== 2 && i.value !== feedBackStatusValue;
    })
    ?.map((i) => ({
      label: (
        <div className="centerImgText">
          <img src={getStatusIcon(i.value)} alt=""></img>
          <div>{i.label}</div>
        </div>
      ),
      key: i.value,
    }));

  const handleStatusChange: MenuProps['onClick'] = async ({ key }) => {
    try {
      setFeedbackData(() => [
        { ...feedbackData[0], task_status_id: Number(key) },
      ]);
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        taskStatusId: Number(key),
        detailsId: detailsId,
      };
      const res = await feedbackService.updateStatusFeedBack(payload);
      if (res.messageId === 1) {
        setFeedbackData(() => [
          { ...feedbackData[0], task_status_id: Number(key) },
        ]);
        updateFeedbackStatus(Number(key), feedbacId, detailsId);
        if (taskListDetails && setTaskListDetails) {
          const updatedTaskListDetails = taskListDetails.task_list_details?.map(
            (i) => {
              if (i.pnc_feedback_details_id === feedbackData[0].details_id) {
                return {
                  ...i,
                  task_status_id: Number(key),
                };
              }
              return { ...i };
            },
          );
          setTaskListDetails({
            ...taskListDetails,
            task_list_details: updatedTaskListDetails,
          });
        }
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_status,
        );
        setFeedbackData(() => [
          {
            ...feedbackData[0],
            task_status_id: Number(feedBackStatusValue),
          },
        ]);
        console.log(res);
      }
    } catch (error) {
      console.log('Status update failed', error);
      setFeedbackData(() => [
        { ...feedbackData[0], task_status_id: Number(feedBackStatusValue) },
      ]);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_status,
      );
    }
  };

  return (
    <Dropdown
      className="statusDropdown"
      menu={{
        items: statusItems,
        onClick: handleStatusChange,
      }}
      trigger={['click']}
      disabled={feedbackData.length === 0}
    >
      <Space>
        <div className="centerImgText">
          <img src={getStatusIcon(feedBackStatusValue)} alt=""></img>
          <div>
            {taskStatusMaster?.filter((i) => i.value === feedBackStatusValue)[0]
              ?.label || 'To Do'}
          </div>
        </div>
      </Space>
    </Dropdown>
  );
};

export default FeedbackStatus;
