import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Popover, TimePicker, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import CCalendar from 'components/sharedComponents/CDatePicker/CCalendar';
import {
  generateUserAvatarEffortField,
  getCurrentOrganization,
} from 'utils/commonFunctions';
import { useLocation } from 'react-router-dom';
import BrowserStorageService from 'services/browserStorage.service';
import { IProjectState } from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import { projectService } from 'services';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

const EffortsField: React.FC<any> = ({
  value,
  taskDetail,
  config,
  sectionId,
  setTaskListDetails,
}: any) => {
  const filterEffortsFieldData = taskDetail?.custom_data?.filter(
    (ele: any) => ele?.mapping_id === config?.mapping_id,
  );
  const [totalHour, setTotalHour] = useState(0);
  const [effortsData, setEffortsData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const [openCalenderIndex, setOpenCalenderIndex] = useState<number | null>(
    null,
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const [error, setError] = useState('');
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const browserStorage = BrowserStorageService.getInstance();
  const userDetails = browserStorage.getUserDetails();

  const defaultTime = <i>00:00</i>;
  const format = 'HH:mm';
  const { hasPermissions } = useRbac();
  const { projectDetails, customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const customCms = customfieldCmsData?.lbl_efforts;

  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  );

  const MainTotalLoggedHour = () => {
    if (!totalHour) {
      return defaultTime;
    } else {
      return (
        <Tooltip
          title={formatDuration(totalHour)}
          color={'#2E364C'}
          placement="left"
        >
          {formatDuration(totalHour)}
        </Tooltip>
      );
    }
  };

  const reset = () => {
    setEffortsData(filterEffortsFieldData?.[0]?.value);
    setIsOpen(false);
    setOpenCalenderIndex(null);
    setEditingIndex(null);
    setError('');
  };

  useEffect(() => {
    if (value) {
      setEffortsData(JSON.parse(JSON.stringify(value)));
    } else {
      const initialEffort = [
        {
          date: dayjs().format('DD/MM/YYYY'),
          user_name: userDetails.full_name,
          duration_in_minutes: null,
          user_id: userDetails?.user_id,
          profile_picture: userDetails?.profile_picture,
        },
      ];
      setEffortsData(initialEffort);
    }
  }, [value, isOpen]);

  useEffect(() => {
    if (taskDetail) {
      setTotalHour(filterEffortsFieldData?.[0]?.total_duration_in_minutes);
    }
  }, [taskDetail]);

  const formatDuration = (totalMinutes: number): string => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours > 0 ? `${hours}h ` : ''}${
      minutes > 0 ? `${minutes}m` : ''
    }`;
  };

  const getTotalMinutes = () => {
    const totalMinutes = effortsData
      ?.filter((effort: any) => !effort.should_delete)
      ?.reduce((sum: number, effort: any) => {
        return sum + (effort.duration_in_minutes || 0);
      }, 0);

    return formatDuration(totalMinutes);
  };

  let removeEmptyDurationFromPayload = effortsData?.filter(
    (ele: any) => ele?.duration_in_minutes !== null,
  );

  const saveEffort = async () => {
    let savePayload = {
      organizationId: getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!,
      project_id: projectDetails?.project_id!,
      section_id: sectionId,
      section_details: [
        {
          task_id: taskDetail?.task_id,
          custom_data: [
            {
              value: removeEmptyDurationFromPayload,
              type: 'effort',
              mapping_id: config?.mapping_id,
            },
          ],
          mapping_id_to_be_updated: config?.mapping_id,
        },
      ],
    };

    try {
      const { data, messageId } = await projectService.updateTaskV2(
        savePayload,
      );
      if (messageId === 1 && data?.length) {
        setTaskListDetails((prev: any) => ({
          ...prev,
          task_list_details: data,
        }));
        const totalDuration = data[0]?.custom_data?.filter(
          (ele: any) => ele.mapping_id === config?.mapping_id,
        );
        setTotalHour(totalDuration[0]?.total_duration_in_minutes);
      }
    } catch (err) {
      console.log(err);
    } finally {
      reset();
    }
  };

  const deleteEffortField = useCallback(
    async (i: number) => {
      const newEfforts = [...effortsData];
      const effortToDelete = newEfforts[i];

      if (effortToDelete?.id) {
        newEfforts[i] = { ...effortToDelete, should_delete: true };
      } else {
        newEfforts.splice(i, 1);
      }
      setTimeout(() => setEffortsData([...newEfforts]), 100);
    },
    [effortsData, setEffortsData],
  );

  const addEffortField = useCallback(() => {
    const newField = {
      date: dayjs().format('DD/MM/YYYY'),
      user_name: userDetails?.full_name,
      duration_in_minutes: null,
      user_id: userDetails?.user_id,
      profile_picture: userDetails?.profile_picture,
    };
    setEffortsData((prev: any) => [...prev, newField]);
  }, [userDetails]);

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);
  dayjs.extend(isYesterday);

  const formatDate = (dateString: any) => {
    const givenDate = dayjs(dateString, 'DD/MM/YYYY');
    if (givenDate.isToday()) {
      return 'Today';
    } else if (givenDate.isTomorrow()) {
      return 'Tomorrow';
    } else if (givenDate.isYesterday()) {
      return 'Yesterday';
    } else {
      return givenDate.format('D MMM');
    }
  };

  const hourMinutesConvertor = (time: any) => {
    if (time) {
      const hours = time.hour();
      const minutes = time.minute();
      const timeFormatted = `${hours}h ${minutes > 0 ? `${minutes}m` : ''}`;
      const totalMinutes = hours * 60 + minutes;
      return { timeFormatted, totalMinutes };
    }
    return { timeFormatted: '', totalMinutes: null };
  };

  const onTimeChange = (time: any, _timeString: any, index: any) => {
    if (time) {
      const { totalMinutes } = hourMinutesConvertor(time);
      const newEfforts = [...effortsData];

      const selectedEffort = newEfforts[index];
      let selectedDate = selectedEffort.date;
      let existingMinute = selectedEffort.duration_in_minutes;

      let hourPerDate = newEfforts
        .filter((ele: any) => ele.date === selectedDate)
        .reduce((sum: number, effort: any) => {
          return sum + (effort.duration_in_minutes || 0);
        }, 0);

      if (
        hourPerDate + totalMinutes - (selectedEffort.duration_in_minutes || 0) <
        1440
      ) {
        selectedEffort.duration_in_minutes = totalMinutes;
        setError('');
      } else {
        selectedEffort.duration_in_minutes = existingMinute ?? 0;
        setEditingIndex(index);
        setError(customCms?.lbl_err_time);
      }

      setEffortsData(newEfforts);
    }
  };

  const onDateOk = useCallback(
    (i: number) => {
      if (!selectedDate) return;

      const updateEffortsDate = [...effortsData];
      const newDate = selectedDate.format('DD/MM/YYYY');
      const selectedEffort = updateEffortsDate[i];
      let duration = selectedEffort?.duration_in_minutes || 0;
      let currentDate = selectedEffort?.date;

      let hourPerDate = updateEffortsDate
        ?.filter((ele: any) => ele.date === newDate)
        ?.reduce(
          (sum: number, effort: any) => sum + (effort.duration_in_minutes || 0),
          0,
        );

      const totalHourPerDay = hourPerDate + duration;

      if (totalHourPerDay <= 1440) {
        updateEffortsDate[i].date = newDate;
        setError('');
      } else {
        updateEffortsDate[i].date = currentDate;
        setError(customCms?.lbl_err_date);
      }

      setEffortsData(updateEffortsDate);
      setOpenCalenderIndex(null);
    },
    [selectedDate, effortsData],
  );

  const onMainOpenChange = (visible: boolean) => {
    if (visible) {
      setIsOpen(visible);
    } else {
      setIsOpen(false);
    }
  };

  const LogLists2 = useMemo(() => {
    return effortsData?.map((ele: any, index: any) => {
      if (ele.should_delete) return null;
      return (
        <div key={ele.id || index}>
          {!ele.duration_in_minutes || editingIndex === index ? (
            <TimePicker
              autoFocus
              onChange={(time, timeString) =>
                onTimeChange(time, timeString, index)
              }
              value={
                ele.duration_in_minutes !== null
                  ? dayjs()
                      .set('hour', Math.floor(ele.duration_in_minutes / 60))
                      .set('minute', ele.duration_in_minutes % 60)
                  : null
              }
              format={format}
              suffixIcon={false}
              size="middle"
              showNow={false}
              hourStep={1}
              placement="bottomLeft"
              onOk={() => setEditingIndex(null)}
              changeOnScroll
            />
          ) : (
            <span
              onClick={() => {
                if (allowedToEdit) {
                  setEditingIndex(index);
                }
              }}
            >
              <Tooltip
                title={formatDuration(ele.duration_in_minutes)}
                color={'#2E364C'}
              >
                {formatDuration(ele.duration_in_minutes)}
              </Tooltip>
            </span>
          )}
          <Popover
            placement="top"
            content={
              <CCalendar
                value={selectedDate}
                onChange={(date) => {
                  if (date) {
                    setSelectedDate(date);
                  }
                }}
                onOk={() => {
                  if (selectedDate) {
                    onDateOk(index);
                  }
                  setOpenCalenderIndex(null);
                }}
                onClear={() => setOpenCalenderIndex(null)}
                disableNextDate={true}
                open={true}
                isUsedInCustomFields={true}
                dateString={dayjs(selectedDate).format('DD/MM/YYYY')}
              />
            }
            trigger="click"
            overlayClassName="gridAssigneeListPopOver"
            open={openCalenderIndex === index}
            onOpenChange={(open) => setOpenCalenderIndex(open ? index : null)}
          >
            <div
              onClick={() => {
                if (allowedToEdit) {
                  setEditingIndex(null);
                  setOpenCalenderIndex(index);
                  setSelectedDate(dayjs(ele.date, 'DD/MM/YYYY'));
                }
              }}
            >
              <Tooltip title={formatDate(ele.date)} color={'#2E364C'}>
                {formatDate(ele.date)}
              </Tooltip>
            </div>
          </Popover>
          <div>
            <span>
              <div
                className={`userAvtr ${
                  generateUserAvatarEffortField(ele)?.key === 'url'
                    ? 'userAvtrImg'
                    : ''
                }`}
              >
                {generateUserAvatarEffortField(ele)?.key === 'string' ? (
                  <Tooltip
                    title={ele?.user_name || ele?.user_email}
                    color={'#2E364C'}
                  >
                    {generateUserAvatarEffortField(ele)?.value}
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={ele?.user_name || ele?.user_email}
                    color={'#2E364C'}
                  >
                    <img
                      src={generateUserAvatarEffortField(ele)?.value}
                      alt=""
                    />
                  </Tooltip>
                )}
              </div>
            </span>
            <span
              onClick={() => {
                deleteEffortField(index);
                setError('');
              }}
            >
              x
            </span>
          </div>
        </div>
      );
    });
  }, [
    effortsData,
    editingIndex,
    openCalenderIndex,
    selectedDate,
    onTimeChange,
  ]);

  const content = (
    <div>
      <h3>{getTotalMinutes() ? getTotalMinutes() : defaultTime}</h3>
      {LogLists2}
      {error && <span>{error}</span>}
      <div>
        <span
          className="btnStyle1 noStyle"
          onClick={() => {
            if (allowedToEdit) {
              setEditingIndex(null);
              setError('');
              addEffortField();
            }
          }}
        >
          {customCms?.lbl_effort_add}
        </span>
        <div>
          <span
            className="btnStyle1 noStyle"
            onClick={() => {
              if (allowedToEdit) {
                reset();
              }
            }}
          >
            {customCms?.lbl_effort_cancel}
          </span>
          <span
            onClick={() => {
              if (allowedToEdit) {
                saveEffort();
              }
            }}
            className="btnStyle1"
          >
            {customCms?.lbl_effort_save}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      arrow={false}
      placement="bottom"
      title={customCms?.lbl_effort_title}
      trigger={'click'}
      content={content}
      open={isOpen}
      onOpenChange={onMainOpenChange}
    >
      {MainTotalLoggedHour()}
    </Popover>
  );
};

export default EffortsField;
