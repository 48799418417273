import { FC, useEffect, useState, useRef } from 'react';
import { Dropdown, MenuProps, message, Skeleton, Tooltip } from 'antd';
import copyIconDark from 'assets/images/fileSearchOutlinedDark.svg';
import closeWSmallDark from 'assets/images/close-icon-dark.svg';
import {
  getCurrentOrganization,
  getInitialName,
  getTimeFromNow,
  modifyMessageString,
  removeElementByIdSuffix,
  sortRepliesByReportedOn,
} from 'utils/commonFunctions';
import { useFeedbackStore } from 'stores/feedback.store';
import feedbackService from 'services/pncService/feedback.service';
import moreIcon from 'assets/images/more-horizontal.svg';
import { useProjectDashboardStore, useUserStore } from 'stores';
import {
  IFeedbackDetailsData,
  IPncDropdownItems,
  IReplyData,
  TiptapActionType,
} from 'models/interface/pnc.interface';
import Editor from 'components/sharedComponents/Editor/Editor';
import { useModalStore } from 'stores/modal.store';
import { IModalState, ITaskList, ITaskListDetails } from 'models/interface';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import projectService from 'services/ProjectService/project.service';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import Draggable from 'react-draggable';

const FeedbackMessageBox: FC<{
  feedbackDetailsId: number;
  handleCancel: () => void;
  feedbackId: number;
  type?: 'image' | 'video';
  showHeader?: boolean;
  isDraggable?: boolean;
  feedbackData: IFeedbackDetailsData[];
  setFeedbackData: any;
  isLoading: boolean;
  autoFocus?: boolean;
  taskListDetails?: ITaskList;
  setTaskListDetails?: React.Dispatch<React.SetStateAction<ITaskList>>;
  asset_url?: string;
}> = ({
  feedbackDetailsId,
  handleCancel,
  feedbackId,
  type = 'image',
  showHeader = true,
  isDraggable = false,
  feedbackData,
  setFeedbackData,
  isLoading,
  autoFocus = true,
  taskListDetails,
  setTaskListDetails,
  asset_url
}) => {
  const {
    setRightDrawerPayload,
    setIsRightDrawerOpen,
    updateFeedbackStatus,
    updateFeedbackList,
    deleteFeedbackDetailsData,
  } = useFeedbackStore((state: any) => state);
  const { projectDetails } = useProjectDashboardStore();
  const { userDetails } = useUserStore((state: any) => state);

  const [typeTiptap, setTypeTiptap] = useState<TiptapActionType>('reply');
  const [replyId, setReplyId] = useState<number>();
  const [editContent, setEditContent] = useState<string>('');
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state,
  );
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();
  const feedbackMsgBodyRef = useRef<HTMLDivElement>(null);
  const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
  const [taskName, setTaskName] = useState<string>('');

  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const items: MenuProps['items'] =
    pncFeedbackSpecificCmsData?.feedback_popup_fb_dropdown_items?.map(
      (i: IPncDropdownItems) => ({
        key: i.key,
        label: (
          <span className="optionWrap">
            <span
              className={`cmnIcon ${
                i.key === 'edit_feedback' ? 'edit' : 'actionIons deleteBin'
              }`}
            ></span>
            <span>{i.label}</span>
          </span>
        ),
        disabled:
          i.key === 'delete_feedback' &&
          feedbackData[0]?.reply_data?.length !== 0,
      }),
    );

  const replyItems: MenuProps['items'] =
    pncFeedbackSpecificCmsData?.feedback_popup_reply_dropdown_items?.map(
      (i: IPncDropdownItems) => ({
        key: i.key,
        label: (
          <span className="optionWrap">
            <span
              className={`cmnIcon ${
                i.key === 'edit_reply' ? 'edit' : 'actionIons deleteBin'
              }`}
            ></span>
            <span>{i.label}</span>
          </span>
        ),
      }),
    );

  useEffect(() => {
    if (type === 'video' && !feedbackDetailsId || feedbackData.length === 0) {
      setTypeTiptap('newFeedback');
    } else {
      setTypeTiptap('reply')
    }
  }, [feedbackData]);

  useEffect(() => {
    if (scrollToBottom && feedbackMsgBodyRef.current) {
      const messageElements =
        feedbackMsgBodyRef.current.querySelectorAll('.messages');
      const lastMessage = messageElements[messageElements.length - 1];
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'smooth' });
      }
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  //Feedback Handlers

  const getCurrentTimeAsTimestamp = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);
    let milliseconds = ('00' + date.getMilliseconds()).slice(-3);

    let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedDate;
  };

  const handleDeleteFeedback = async (fbId: number) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: fbId,
    };
    try {
      const response = await feedbackService.deleteFeedBack(payload);
      if (response?.messageId === 1) {
        setFeedbackData([]);
        setTypeTiptap('newFeedback');
        deleteFeedbackDetailsData(feedbackId, feedbackDetailsId);
        if (type === 'image') {
          handleCancel();
          removeElementByIdSuffix(fbId);
        }
        message.success(
          pncFeedbackSpecificCmsData?.toaster_msgs?.success_del_fb_details,
        );
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_del_fb_details,
        );
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_edit_fb_details,
      );
    } finally {
      modalCloseHandler();
    }
  };

  const handleDeleteReply = async (replyId: number) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      replyId: replyId,
    };
    try {
      const response = await feedbackService.deleteReply(payload);
      if (response?.messageId === 1) {
        let updatedFeedbackReplyData = feedbackData[0]?.reply_data?.filter(
          (i: IReplyData) => i.reply_id !== replyId,
        );
        setFeedbackData(() => [
          { ...feedbackData[0], reply_data: updatedFeedbackReplyData },
        ]);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_del_fb_reply,
        );
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_del_fb_reply,
      );
    }
  };

  const onClickFeedbackActons: MenuProps['onClick'] = ({ key }) => {
    if (key === 'edit_feedback') {
      setTypeTiptap('editFeedback');
      setEditContent(feedbackData[0]?.feedback);
    }
    if (key === 'delete_feedback') {
      handleCancel();
      setModalState({
        modalHeader: (
          <>
            <h3>{pncFeedbackSpecificCmsData?.del_modal_heading}</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalCloseHandler()}
              onKeyDown={() => {}}
            >
              close
            </span>
          </>
        ),
        modalBody: (
          <>
            <div className="icon"></div>
            <p>{pncFeedbackSpecificCmsData?.del_modal_body}</p>
          </>
        ),
        modalFooter: (
          <>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalCloseHandler()}
            >
              {pncFeedbackSpecificCmsData?.btn_cta_cancel}
            </button>

            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await handleDeleteFeedback(feedbackData[0]?.details_id);
                })()
              }
            >
              {pncFeedbackSpecificCmsData?.btn_cta_confirm}
            </button>
          </>
        ),
        shouldShow: true,
      });
    }
  };

  const modalCloseHandler = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const onClickReplyActons =
    (reply: IReplyData): MenuProps['onClick'] =>
    ({ key }) => {
      if (key === 'edit_reply') {
        setTypeTiptap('editReply');
        setEditContent(reply?.reply);
        setReplyId(reply?.reply_id);
      }
      if (key === 'delete_reply') {
        handleDeleteReply(Number(reply?.reply_id));
      }
    };

  const handleResolveFb = async (statusId: number, detailId: number) => {
    try {
      let newStatusId = statusId;
      if (feedbackData[0]?.due_date && statusId === 1) {
        newStatusId =
          dayjs(feedbackData[0].due_date).diff(
            dayjs(new Date().setHours(0, 0, 0, 0)),
            'days',
          ) < 0
            ? 2
            : statusId;
      }
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        taskStatusId: newStatusId,
        detailsId: detailId,
      };
      const res = await feedbackService.updateStatusFeedBack(payload);
      if (res.messageId === 1) {
        setFeedbackData(() => [
          { ...feedbackData[0], task_status_id: statusId },
        ]);
        updateFeedbackStatus(statusId, feedbackId, detailId);
        if (taskListDetails && setTaskListDetails) {
          const updatedTaskListDetails = taskListDetails.task_list_details?.map(
            (i) => {
              if (i.pnc_feedback_details_id === feedbackData[0].details_id) {
                return {
                  ...i,
                  task_status_id: newStatusId,
                };
              }
              return { ...i };
            },
          );
          setTaskListDetails({
            ...taskListDetails,
            task_list_details: updatedTaskListDetails,
          });
        }
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_status,
        );
        console.log(res);
      }
    } catch (error) {
      console.log('Resolve Failed', error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_status,
      );
    }
  };

  const handleEditReply = async (editor: string, taggedUserIds: number[]) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      replyId: replyId as number,
      reply: modifyMessageString(editor),
    };
    try {
      const response = await feedbackService.editReply(payload);
      if (response?.messageId === 1 && feedbackData[0]?.reply_data) {
        const updatedReplyData = feedbackData[0]
          ? [
              ...feedbackData[0].reply_data.map((i: IReplyData) => {
                if (i.reply_id === payload.replyId) {
                  return {
                    ...i,
                    reply: payload.reply,
                  };
                } else {
                  return i;
                }
              }),
            ]
          : [];
        setFeedbackData(() => [
          { ...feedbackData[0], reply_data: updatedReplyData },
        ]);
        taggedUserIds && sendNotification(editor, taggedUserIds);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_edit_fb_reply,
        );
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_edit_fb_reply,
      );
    } finally {
      setTypeTiptap && setTypeTiptap('reply');
    }
  };

  const handleEditFb = async (editor: string, taggedUserIds: number[]) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: feedbackData[0]?.details_id,
      feedback: modifyMessageString(editor),
      taskName: taskName,
    };
    try {
      const response = await feedbackService.editFeedBack(payload);
      if (response?.messageId === 1) {
        const updatedFeedback = [
          { ...feedbackData[0], feedback: payload.feedback },
        ];
        setFeedbackData(updatedFeedback);
        taggedUserIds && sendNotification(editor, taggedUserIds);
        if (taskListDetails && setTaskListDetails) {
          const updatedTaskListDetails = taskListDetails.task_list_details?.map(
            (i) => {
              if (i.pnc_feedback_details_id === feedbackData[0].details_id) {
                return {
                  ...i,
                  task_name: taskName,
                };
              }
              return { ...i };
            },
          );
          setTaskListDetails({
            ...taskListDetails,
            task_list_details: updatedTaskListDetails,
          });
        }
        setTaskName('');
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_edit_fb_details,
        );
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_edit_fb_details,
      );
    } finally {
      setTypeTiptap && setTypeTiptap('reply');
    }
  };

  const handleReply = async (editor: string, taggedUserIds: number[]) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: feedbackData[0]?.details_id,
      reportedBy: userDetails?.user_id,
      reply: modifyMessageString(editor),
    };
    try {
      const response = await feedbackService.replyFeedBack(payload);
      if (response?.messageId === 1 && feedbackData[0]?.reply_data) {
        const newReply = {
          reply: payload.reply as string,
          reply_id: response.data.feedback_reply_id as number,
          full_name: userDetails?.full_name as string,
          reported_by: parseInt(userDetails?.user_id as string),
          reported_on: getCurrentTimeAsTimestamp(),
        };
        setFeedbackData([
          {
            ...feedbackData[0],
            reply_data: [...feedbackData[0].reply_data, newReply],
          },
        ]);
        setScrollToBottom(true);
        taggedUserIds && sendNotification(editor, taggedUserIds);
      } else {
        console.log(response);
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_fb_reply,
        );
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_fb_reply,
      );
    }
  };

  const handleSaveFb = async (editor: string, taggedUserIds: number[]) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      feedbackId: feedbackId,
      feedbackBy: userDetails?.user_id,
      feedback: modifyMessageString(editor),
      feedbackCoordinates: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      taskName: taskName,
    };
    try {
      const response: any = await feedbackService.saveFeedback(payload);
      if (response?.data.messageId === 1) {
        const newFeedback = {
          email: userDetails?.email,
          details_id: response?.data.data.details_id,
          reported_by: parseInt(userDetails?.user_id as string),
          reported_on: getCurrentTimeAsTimestamp(),
          feedback: modifyMessageString(editor),
          emotion: null,
          sentiment: null,
          full_name: userDetails?.full_name,
          feedback_id: response?.data.data.feedback_id,
          feedback_coordinates: {
            top: 0,
            left: 0,
            width: 0,
            height: 0,
          },
          task_status_id: 1,
          task_priority_id: 2,
          is_deleted: false,
          reply_data: [],
        };
        setFeedbackData([{ ...newFeedback }]);
        taggedUserIds && sendNotification(editor, taggedUserIds);
        updateFeedbackList(feedbackId, newFeedback);
        message.success(
          pncFeedbackSpecificCmsData?.toaster_msgs?.success_save_fb_details,
        );
        if (taskListDetails && setTaskListDetails) {
          const newTask = {
            task_id:response?.data.data.task_id,
            due_date:null,
            task_name: taskName,
            task_rank:999,
            created_by:parseInt(userDetails?.user_id as string),
            assignee_dp:null,
            assignee_id:null,
            assigner_id:null,
            custom_data:null,
            assignee_name:null,
            assignee_email:null,
            task_status_id:1,
            pnc_feedback_id:response?.data.data.feedback_id,
            task_priority_id:2,
            task_status_name:"To Do",
            assignee_inactive:userDetails?.organization_info?.find((i: any) => i.organization_id === projectDetails?.organization_id)?.inactive,
            task_priority_name:"Normal",
            pnc_feedback_asset_url: asset_url,
            pnc_feedback_details_id: response?.data.data.details_id
            }
          const updatedTaskListDetails = [...taskListDetails?.task_list_details ?? [],newTask]
          setTaskListDetails({
            ...taskListDetails,
            task_list_details: updatedTaskListDetails as ITaskListDetails[],
          });
        }
        setTaskName('');
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_fb_details,
        );
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_save_fb_details,
      );
    } finally {
      setTypeTiptap('reply');
    }
  };

  const handleRightDrawer = (feedbackIdValue: number) => {
    handleCancel();
    setIsRightDrawerOpen(true);
    if (taskListDetails && setTaskListDetails) {
      setRightDrawerPayload({
        feedbackId: feedbackIdValue,
        fbDetailsId: feedbackData[0]?.details_id,
        taskListDetails: taskListDetails,
        setTaskListDetails: setTaskListDetails,
      });
    } else {
      setRightDrawerPayload({
        feedbackId: feedbackIdValue,
        fbDetailsId: feedbackData[0]?.details_id,
      });
    }
  };

  const sendNotification = async (editorData: any, taggedUsers: number[]) => {
    try {
      await projectService.sendMentionNotification({
        users: taggedUsers.filter((e) => e !== userDetails.user_id),
        senderName: userDetails.full_name ?? userDetails.email,
        projectName: projectDetails?.project_name,
        orgKey: org_key,
        teamName: getCurrentOrganization(org_key, userDetails.organization_info)
          ?.organization_name!,
        taskName: feedbackData[0]?.feedback.replace(/<\/?[^>]+(>|$)/g, ''),
        sectionName: 'sectionName',
        messageContent: editorData,
        projectId: projectDetails?.project_id,
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        date: dayjs().format('MMM D, YYYY'),
        time: dayjs().format('h:mma'),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendMessage = () => {
    switch (typeTiptap) {
      case 'editReply':
        return handleEditReply;
      case 'editFeedback':
        return handleEditFb;
      case 'reply':
        return handleReply;
      case 'newFeedback':
        return handleSaveFb;
      default:
        return null;
    }
  };

  return (
    <Draggable handle=".feedbackHeader" disabled={!isDraggable}>
      <div className="submittingFeedbackPopup">
        {isLoading ? (
          <>
            <Skeleton avatar active paragraph={{ rows: 1 }} />
            <br />
            <Skeleton avatar active paragraph={{ rows: 1 }} />
            <br />
            <Skeleton.Input active size="large" block={true} />
          </>
        ) : (
          <>
            {showHeader && (
              <div className="feedbackHeader">
                <div className="title">
                  {pncFeedbackSpecificCmsData?.feedback_popup_heading} - #
                  {feedbackData[0]?.is_deleted === false &&
                    feedbackData[0]?.details_id}
                </div>
                <ul className="feedbackActions">
                  <li
                    onClick={() =>
                      handleResolveFb(
                        feedbackData[0]?.task_status_id === 3 ? 1 : 3,
                        feedbackData[0]?.details_id,
                      )
                    }
                  >
                    <Tooltip
                      title={
                        pncFeedbackSpecificCmsData?.lbl_tooltips?.lbl_resolve_fb
                      }
                      placement="bottom"
                      color="#2E364C"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill={
                            feedbackData[0]?.task_status_id === 3
                              ? 'green'
                              : '#2E364C'
                          }
                          d="M8.77067 13.7917L14.6665 7.89585L13.7082 6.95835L8.77067 11.8959L6.27067 9.39585L5.33317 10.3334L8.77067 13.7917ZM9.99984 18.3334C8.86095 18.3334 7.78456 18.1146 6.77067 17.6771C5.75678 17.2396 4.87136 16.6424 4.11442 15.8854C3.35748 15.1285 2.76025 14.2431 2.32275 13.2292C1.88525 12.2153 1.6665 11.1389 1.6665 10C1.6665 8.84724 1.88525 7.76391 2.32275 6.75002C2.76025 5.73613 3.35748 4.85419 4.11442 4.10419C4.87136 3.35419 5.75678 2.76044 6.77067 2.32294C7.78456 1.88544 8.86095 1.66669 9.99984 1.66669C11.1526 1.66669 12.2359 1.88544 13.2498 2.32294C14.2637 2.76044 15.1457 3.35419 15.8957 4.10419C16.6457 4.85419 17.2394 5.73613 17.6769 6.75002C18.1144 7.76391 18.3332 8.84724 18.3332 10C18.3332 11.1389 18.1144 12.2153 17.6769 13.2292C17.2394 14.2431 16.6457 15.1285 15.8957 15.8854C15.1457 16.6424 14.2637 17.2396 13.2498 17.6771C12.2359 18.1146 11.1526 18.3334 9.99984 18.3334ZM9.99984 17.0834C11.9721 17.0834 13.6457 16.3924 15.0207 15.0104C16.3957 13.6285 17.0832 11.9584 17.0832 10C17.0832 8.0278 16.3957 6.35419 15.0207 4.97919C13.6457 3.60419 11.9721 2.91669 9.99984 2.91669C8.0415 2.91669 6.37136 3.60419 4.98942 4.97919C3.60748 6.35419 2.9165 8.0278 2.9165 10C2.9165 11.9584 3.60748 13.6285 4.98942 15.0104C6.37136 16.3924 8.0415 17.0834 9.99984 17.0834Z"
                        />
                      </svg>
                    </Tooltip>
                  </li>
                  <li onClick={() => handleRightDrawer(feedbackId)}>
                    <Tooltip
                      title={
                        pncFeedbackSpecificCmsData?.lbl_tooltips?.lbl_more_info
                      }
                      placement="bottom"
                      color="#2E364C"
                    >
                      <img src={copyIconDark} alt="" />
                    </Tooltip>
                  </li>
                  <li>
                    <img src={closeWSmallDark} alt="" onClick={handleCancel} />
                  </li>
                </ul>
              </div>
            )}
            <div className="feedbackMsgBody" ref={feedbackMsgBodyRef}>
              {typeTiptap !== 'newFeedback' && feedbackData.length > 0 && (
                <div className="messages">
                  <div className="messagesHeader messagesHeaderWrap">
                    <div className="userAvtr">
                      {getInitialName(feedbackData[0]?.full_name)}
                    </div>
                    <div className="msgArea flexCol">
                      <div className="msgAreaInner">
                        <div className="msgAreaInnerHeader">
                          <span className="userName feedback">
                            <Tooltip
                              title={feedbackData[0]?.full_name}
                              color={'#2E364C'}
                              placement="right"
                            >
                              {feedbackData[0]?.full_name}
                            </Tooltip>
                          </span>
                          <span className="msgDueDate feedback">
                            {pncFeedbackSpecificCmsData &&
                              getTimeFromNow(
                                feedbackData[0]?.reported_on,
                                pncFeedbackSpecificCmsData?.feedback_popup_timestamps,
                              )}
                          </span>
                        </div>
                        {userDetails?.user_id ===
                          feedbackData[0]?.reported_by && (
                          <Dropdown
                            menu={{ items, onClick: onClickFeedbackActons }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <span className="moreDropdown">
                              <img src={moreIcon} alt="" />
                            </span>
                          </Dropdown>
                        )}
                      </div>
                      <div className="msgParagraph feedback">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feedbackData[0]?.feedback,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {feedbackData[0]?.reply_data &&
                Array.isArray(feedbackData[0]?.reply_data) &&
                sortRepliesByReportedOn(feedbackData[0]?.reply_data)?.map(
                  (reply: IReplyData) => {
                    return (
                      <div key={reply.reply_id} className="messages">
                        <div className="messagesHeader messagesHeaderWrap">
                          <div className="userAvtr">
                            {getInitialName(reply?.full_name)}
                          </div>
                          <div className="msgArea flexCol">
                            <div className="msgAreaInner">
                              <div className="msgAreaInnerHeader">
                                <span className="userName feedback">
                                  <Tooltip
                                    title={reply?.full_name}
                                    color={'#2E364C'}
                                    placement="right"
                                  >
                                    {reply?.full_name}
                                  </Tooltip>
                                </span>
                                <span className="msgDueDate feedback">
                                  {pncFeedbackSpecificCmsData &&
                                    getTimeFromNow(
                                      reply?.reported_on,
                                      pncFeedbackSpecificCmsData?.feedback_popup_timestamps,
                                    )}
                                </span>
                              </div>
                              {userDetails?.user_id === reply.reported_by && (
                                <Dropdown
                                  menu={{
                                    items: replyItems,
                                    onClick: onClickReplyActons(reply),
                                  }}
                                  placement="bottomRight"
                                  trigger={['click']}
                                >
                                  <span className="moreDropdown">
                                    <img src={moreIcon} alt="" />
                                  </span>
                                </Dropdown>
                              )}
                            </div>

                            <div className="msgParagraph feedback">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: reply?.reply,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
            </div>
            <div className="messages editorSec messageBox messageBoxFull">
              <div className="messagesHeader">
                <div className="userAvtr">
                  {userDetails?.full_name &&
                    getInitialName(userDetails.full_name)}
                </div>
                <div className="msgEditor commentContainer">
                  <Editor
                    editing={true}
                    messageContent={editContent}
                    setEditContent={setEditContent}
                    setContentAsText={setTaskName}
                    autoFocus={autoFocus}
                    allowedExtensions={{
                      showBoldOutlined: true,
                      showItalicOutlined: true,
                      showUnorderedListOutlined: true,
                      showEmojiControl: true,
                      showUploadOutlined: true,
                      showLinkOutlined: true,
                      showSend: true,
                      showCheckList: true,
                    }}
                    placeHolder={
                      typeTiptap === 'reply'
                        ? pncFeedbackSpecificCmsData?.feedback_popup_editor_placeholder
                        : ''
                    }
                    editorClassName="messageThread"
                    sendMessage={handleSendMessage()}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Draggable>
  );
};

export default FeedbackMessageBox;
